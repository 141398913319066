@import "tailwindcss/base.css";
@import "tailwindcss/utilities.css";
@import "tailwindcss/components.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
// @import "./app/theme/theme.scss";

html {
  scroll-behavior: smooth;
}

* {
  color: #1a1f22;
  --dea-navbar-height: 64px;
  --dea-footer-height: 100px;
  --dea-wrapper-height: calc(100vh - 64px - 100px - 64px);
  --dea-primary-color: #1570a8;
  --dea-grey: rgb(249, 248, 250);
  --step-bar-primary-color: #1570a8;
  --step-bar-grey: rgb(223, 222, 222);
  --danger-color: #dd524c;
}

.p-button {
  padding: initial;
}

.tw-w-full > .p-dropdown {
  width: 100%;
}

.text-danger,
.text-danger > * {
  color: var(--danger-color);
}
